export const socialsData = {
    github: 'https://github.com/Schoksi20',
    // facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/shrey-choksi-40656a223/',
    // instagram: 'https://www.instagram.com/',
    // codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/shrey__choksi',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}