export const experienceData = [
  {
    id: 1,
    company:
      "Developed a website for a client who has a business of helping students in USA admissions",
    jobtitle: "Web Specialist",
    startYear: "May 2023",
    endYear: "July 2023",
  },
  {
    id: 2,
    company:
      "Developed a freelance website for a client in the business of stainless steel containers",
    jobtitle: "Project Lead Developer",
    startYear: "Feb 2022",
    endYear: "May 2022",
  },
  {
    id: 3,
    company:
      "We offer IT services and also provide internship to students in different fields of the industry.",
    jobtitle: "Co-Founder / Director - Grownited Pvt. Ltd.",
    startYear: "2020",
    endYear: "2022",
  },
  {
    id: 4,
    company:
      "H&S is a digital connectivity platform which facilitates a sports booking platform for turfs and tournaments.",
    jobtitle: "Co-Founder / CTO - Huddle & Score",
    startYear: "2020",
    endYear: "2022",
  },
  {
    id: 5,
    company:
      "Worked part time in this institute as an IT Tutor and trained roughly 80 students in multiple different programming languages.",
    jobtitle: "IT Tutor - Royal Technosoft Pvt. Ltd",
    startYear: "2017",
    endYear: "2019",
  },
];
