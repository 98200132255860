import oracle from "../assets/png/oracle.jpeg"

export const achievementData = {
    bio : "I've always wanted to showcase my knowledge in the field of programming languages and what better way to do that but to give the respective exams. Here's a few international exams I have given over the years and my scores in the same.",
    achievements : [
        {
            id : 1,
            title : 'OCA (Oracle Certified Administrator) - 98% Result',
            details : 'The OCA credential ensures a candidate is equipped with fundamental skills, providing a strong foundation for supporting Oracle products.',
            date : 'April 27, 2018',
            field : 'Development',
            image : oracle,
            link : "https://drive.google.com/file/d/1vm8oFkj9j2YakgwnYY1UpQsxV7GEsVQS/view?usp=sharing"
        },
        {
            id : 2,
            title : 'OCWCD (Oracle Certified Web Component Developer) - 92% Result',
            details : 'The OCWCD Exam tests your knowledge of various aspects of Java Web Components, especially JSPs, Servlets and Java Beans.',
            date : 'Jan 18, 2018',
            field : 'Development',
            image : oracle,
            link : "https://www.credly.com/badges/4350a7f3-9473-4a6f-a624-2d91ad4fed68/public_url"
        },
        {
            id : 3,
            title : 'OCJP (Oracle Certified Java Programmer) - 100% Result',
            details : 'The main benefit of OCJP certification is that it helps in improving knowledge and in-depth understanding of the Java language.',
            date : 'Nov 9, 2017',
            field : 'Development',
            image : oracle,
            link : "https://www.credly.com/badges/57e036b4-00f0-4860-8161-899bb5bddca2/public_url"
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/