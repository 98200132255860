/* eslint-disable */
import { BiShoppingBag, BiPencil, BiCodeAlt, BiTestTube, BiSupport } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import { IoMdAnalytics } from "react-icons/io"
import {DiGitBranch} from "react-icons/di";
import { FaInternetExplorer,  FaChalkboardTeacher, FaCameraRetro, FaPinterest, FaVideo, FaTabletAlt, FaRegNewspaper } from "react-icons/fa";
import analytics1 from "../assets/svg/services/analytics.svg";


export const servicesData = [
    
    {
        id: 1,
        title: 'Website Analytics',
        icon: <IoMdAnalytics />
    },
    {
        id: 2,
        title: 'Code Management',
        icon: <DiGitBranch />
    },
    {
        id: 3,
        title: 'Tailored Development',
        icon: <BiCodeAlt />
    },
    {
        id: 4,
        title: 'Rigorous Testing',
        icon: <BiTestTube />
    },
    {
        id: 5,
        title: 'Ongoing Support',
        icon: <BiSupport />
    },

]

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.