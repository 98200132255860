/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'

import dhiraj from "../assets/videos/dhiraj.mp4"
import mihir from "../assets/videos/mihir.mp4";
import jaimin from "../assets/videos/jaimin.mp4";

export const testimonialsData = [
    {
        id: 1,
        name: 'Mihir Mehta',
        title: 'Founder at Zoobiz | Sr. Director Consultant at BNI Ahmedabad, Education Consultant, Edupreneur',
        text: "",
        image: boy2,
        video : mihir
    },
    {
        id: 2,
        name: 'Dhiraj Poojara',
        title: "MD - Royal Technosoft Pvt. Ltd, Alumni of DAIICT, Trainer of World's Youngest JAVA Programmer",
        text: "",
        image: boy1,
        video : dhiraj
    },
    {
        id: 3,
        name: 'Jaimin Shah',
        title: 'Entrepreneur, Co-Founder & CEO of DEV IT Ltd, DevX',
        text: "",
        image: boy2,
        video : jaimin
    }
    // {
    //     id: 4,
    //     name: 'Joey Tribbiani',
    //     title: 'Actor',
    //     text: "Making my portfolio with this helped me get my role as 'Dr. Drake Ramoray' in 'Days Of Our Lives'. You guys are the best !",
    //     image: boy1
    // },
    // {
    //     id: 5,
    //     name: 'Rachel Green',
    //     title: 'Sales Executive',
    //     text: 'Ralph Lauren was one of the best things that ever happened to me. My portfolio made that dream happen. I owe you guys.',
    //     image: girl3
    // },
    // {
    //     id: 6,
    //     name: 'Ross Geller',
    //     title: 'Paleontologist',
    //     text: "Be it 'Rocks' or 'Bones', you need a website to display it. This is it. Great work guys !",
    //     image: boy3
    // }
]

