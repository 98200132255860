import bootcamp1 from "../assets/videos/bootcamp1.mp4";
import indus from "../assets/videos/indus.mp4";

export const HighlightsData = [
    {
        id : 1,
        video : bootcamp1
    },
    {
        id : 2,
        video : indus
    },
]