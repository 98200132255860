export const educationData = [
  {
    id: 0,
    institution: "Florida State University",
    course: "Masters of Science in Computer Science",
    startYear: "2023",
    endYear: "2025",
  },
  {
    id: 1,
    institution: "L.D. College of Engineering",
    course: "Bachelors of Engineering in Electronics and Communication",
    startYear: "2019",
    endYear: "2023",
  },
  {
    id: 2,
    institution: "Udgam School For Children",
    course: "Higher Secondary Education",
    startYear: "2017",
    endYear: "2019",
  },
  // {
  //     id: 3,
  //     institution: 'Hawking University, Cambridge',
  //     course: 'Master of Technology',
  //     startYear: '2019',
  //     endYear: 'Present'
  // },
];
