import React from 'react'
import { Helmet } from 'react-helmet'
import { Highlights, Navbar, Footer, Landing, About, Skills, Testimonials, Blog, Education, Experience, Contacts, Projects, Services, Achievement } from '../../components'
import { headerData } from '../../data/headerData'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDn6WUVsMYvuQUhHD3Iop8yfqdgUk9UaoU",
  authDomain: "portfolio-ec24c.firebaseapp.com",
  projectId: "portfolio-ec24c",
  storageBucket: "portfolio-ec24c.appspot.com",
  messagingSenderId: "427336657375",
  appId: "1:427336657375:web:d4143ae9461c56d0157c6a",
  measurementId: "G-FEGHKX2V7F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'Page visited');
// console.log("Visited")
function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - Porfolio</title>
            </Helmet>
            <Navbar />        
            <Landing />
            <About />
            <Experience />
            <Projects />
            <Skills />
            <Services />
            <Achievement />
            <Education />
            <Testimonials />
            <Highlights />
            {/* <Blog /> */}
            <Contacts />
            {/* <Footer /> */}
        </div>
    )
}

export default Main
