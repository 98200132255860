import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";
import Frame from "../assets/svg/projects/Frame.svg";
import royal from "../assets/svg/projects/royal.svg";
import mun from "../assets/svg/projects/mun.svg";
import huddle from "../assets/svg/projects/huddle.svg";
import grownited from "../assets/svg/projects/grownited.svg";
import anantay from "../assets/svg/projects/Frame 4.svg";
import bridg from "../assets/png/logo.jpg";

export const projectsData = [
  {
    id: 1,
    projectName: "Huddle & Score",
    projectDesc:
      "This is a startup project which I Co-founded and developed. It is a digital sports connectivity platform.",
    tags: ["ReactJS", "Sass", "Firebase"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: "https://huddleandscore.com",
    image: huddle,
  },
  {
    id: 2,
    projectName: "Grownited Private Limited",
    projectDesc:
      "This is a website I created of a private limited firm based off Ahmedabad, Gujarat.",
    tags: ["NextJS", "CSS", "Firebase"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: "https://1grownited-web.vercel.app/",
    image: grownited,
  },
  {
    id: 3,
    projectName: "Royal Stainless Steel",
    projectDesc:
      "A website made for a client. It is based on the display of different kinds of drums and containers.",
    tags: ["ReactJS", "CSS", "Material Ui"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: "https://www.royalstainless.com",
    image: royal,
  },
  {
    id: 4,
    projectName: "MyNextMUN",
    projectDesc:
      "Model United Nations is an academic simulation of the United Nations where students play the role of delegates from different countries and attempt to solve real world issues with the policies and perspectives of their assigned country.",
    tags: ["ReactJS", "NodeJS", "StripeJS"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: "https://peaceful-semifreddo-efa220.netlify.app",
    image: mun,
  },
  {
    id: 5,
    projectName: "E-Commerce MERN App",
    projectDesc: "A Simple E-commerce application",
    tags: ["ReactJS", "MERN"],
    // code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/Schoksi20/Ecommerce-Frontend",
    image: five,
  },
  {
    id: 6,
    projectName: "Bridg360",
    projectDesc: "Educational Assistance Website for Abroad students",
    tags: ["ReactJS, Tailwind, Prismic"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://www.bridg360.com",
    image: bridg,
  },
  {
    id: 7,
    projectName: "Anantay",
    projectDesc: "A website for corporate giftings and corporate events.",
    tags: ["React", "HTML", "Bootstrap"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://www.anantay.com",
    image: anantay,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
